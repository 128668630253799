import { EmitterConfig, Vec2PropConfig, FloatPropConfig, FloatPropertyMode, ColorPropertyMode, ColorPropConfig, ScaleAffectorConfig, FadeAffectorConfig, RotateAffectorConfig, ColorBlendAffectorConfig, FlipbookAffectorConfig, ForceAffectorConfig, Vec2PropertyMode } from './pfx/emitterConfig'

// Fill in all optional values for the emitter config - the way I've set things 
// up in the editor (definitely the *wrong* way, in hindsight), all the members
// need to be present ahead of time.
function patchColorProp(prop:ColorPropConfig) {
	if(prop.val === undefined) {
		prop.val = { 
            r: 1,
            g: 1,
            b: 1,
            a: 1
        }
	}
	if(prop.min === undefined) {
		prop.min = { 
            r: 0,
            g: 0,
            b: 0,
            a: 1
        }
	}
	if(prop.max === undefined) {
		prop.max = { 
            r: 1,
            g: 1,
            b: 1,
            a: 1
        }
	}
}

function patchFloatProp(prop:FloatPropConfig, defaultVal:number = 0) {
	if(prop.min === undefined)
		prop.min = 0.0;
	if(prop.max === undefined)
		prop.max = 1.0;
	if(prop.val === undefined)
		prop.val = defaultVal;
}

function patchVec2Prop(prop:Vec2PropConfig) {
	if(prop.min === undefined) {
		prop.min = {
			x: 0,
			y: 0
		}
	}
	if(prop.max === undefined) {
		prop.max = {
			x: 1,
			y: 1
		}
	}
	if(prop.val === undefined) {
		prop.val = {
			x: 0,
			y: 0
		}
	}
}

function ensureFloatPropExists(aff:any,propName:string) {
	if(aff[propName] === undefined) {
		aff[propName] = { mode: FloatPropertyMode.Constant }
	}
    patchFloatProp(aff[propName]);
}

function patchAffectorScale(aff:ScaleAffectorConfig) {
	ensureFloatPropExists(aff, "constSpeed")
	ensureFloatPropExists(aff, "target")

	if(aff.times === undefined) {
		aff.times = [
			{ mode: FloatPropertyMode.Constant },
			{ mode: FloatPropertyMode.Constant },
		]
    }

    patchFloatProp(aff.times[0], 0.5);
    patchFloatProp(aff.times[1], 0.5);
}

function patchAffectorRotate(aff:RotateAffectorConfig) {
	// Right now, this is the exact same as scale, so taking a shortcut until it's no longer true
	patchAffectorScale(aff as unknown as ScaleAffectorConfig);
}

function patchAffectorFade(aff:FadeAffectorConfig) {
	// Right now, this is the exact same as scale, so taking a shortcut until it's no longer true
	patchAffectorScale(aff as unknown as ScaleAffectorConfig);
}

function patchAffectorColorBlend(aff:ColorBlendAffectorConfig) {
	if(aff.target === undefined) {
		aff.target = { mode: ColorPropertyMode.Constant, }
	}
	if(aff.mid === undefined) {
		aff.mid = { mode: ColorPropertyMode.Constant, }
	}
	if(aff.times === undefined) {
		aff.times = [
			{ mode: FloatPropertyMode.Constant },
			{ mode: FloatPropertyMode.Constant },
		]
    }

    patchColorProp(aff.target);
    patchColorProp(aff.mid);
    patchFloatProp(aff.times[0], 0.5);
    patchFloatProp(aff.times[1], 0.5);
} 

function patchAffectorForce(aff:ForceAffectorConfig) {
	if(aff.force === undefined) {
		aff.force = { mode: Vec2PropertyMode.Constant, }
	}

    patchVec2Prop(aff.force);
} 
 
function patchAffectorFlipbook(aff:FlipbookAffectorConfig) {
	ensureFloatPropExists(aff, "holdFrame")
	ensureFloatPropExists(aff, "constSpeed")

	if(aff.loop === undefined) {
		aff.loop = true
	}

	if(aff.times === undefined) {
		aff.times = [
			{ mode: FloatPropertyMode.Constant },
			{ mode: FloatPropertyMode.Constant },
		]
    }

    patchFloatProp(aff.times[0], 0.5);
    patchFloatProp(aff.times[1], 0.5);
}

function patchEmissionShape(emitter:EmitterConfig) {
	if(emitter.boxEmitter === undefined) {
		emitter.boxEmitter = {
			width: { mode: FloatPropertyMode.Constant },
			height: { mode: FloatPropertyMode.Constant },
		}
	}
	if(emitter.circleEmitter === undefined) {
		emitter.circleEmitter = {
			radius: { mode: FloatPropertyMode.Constant },
			edgeOnly: false
		}
    }

    patchFloatProp(emitter.boxEmitter.width);
    patchFloatProp(emitter.boxEmitter.height);
    patchFloatProp(emitter.circleEmitter.radius);
}

function patchEmissionMode(emitter:EmitterConfig) {
	if(emitter.continuous === undefined) {
		emitter.continuous = {
			spawnRate: { mode: FloatPropertyMode.Constant },
		}
	}

	if(emitter.burst === undefined) {
		emitter.burst = {
			repeat: false,
			numParticles: { mode: FloatPropertyMode.Constant },
			timeBetweenBursts: { mode: FloatPropertyMode.Constant },
		}
	}

	patchFloatProp(emitter.continuous.spawnRate, 1);
	patchFloatProp(emitter.burst.numParticles, 10);
	patchFloatProp(emitter.burst.timeBetweenBursts, 1);
}

function patchStartDelay(emitter:EmitterConfig) {
	if(emitter.startDelay === undefined) {
		emitter.startDelay = {
			delayTime: { mode: FloatPropertyMode.Constant },
		}
	}

	patchFloatProp(emitter.startDelay.delayTime, 0);
}

function patchAnims(emitter:EmitterConfig) {
	if(emitter.anims === undefined) {
		emitter.anims = []
	}
}

export function patchEmitter(emitter:EmitterConfig) {
	patchEmissionShape(emitter);
	patchEmissionMode(emitter);
	patchStartDelay(emitter)
	patchColorProp(emitter.color);
	patchFloatProp(emitter.lifetime);
	patchFloatProp(emitter.scale);
	patchFloatProp(emitter.rot);
	patchVec2Prop(emitter.velocity);
	patchAnims(emitter);

	if(emitter.zFollowsY === undefined) {
		emitter.zFollowsY = false
	}

	for(let i = 0; i < emitter.affectors.length; ++i) {
		if(emitter.affectors[i].id == "scale") {
			patchAffectorScale(emitter.affectors[i].cfg);
		} else if(emitter.affectors[i].id == "rotate") {
			patchAffectorRotate(emitter.affectors[i].cfg);
		} else if(emitter.affectors[i].id == "fade") {
			patchAffectorFade(emitter.affectors[i].cfg);
		} else if(emitter.affectors[i].id == "color") {
			patchAffectorColorBlend(emitter.affectors[i].cfg);
		} else if(emitter.affectors[i].id == "force") {
			patchAffectorForce(emitter.affectors[i].cfg);
		} else if(emitter.affectors[i].id == "flipbook") {
			patchAffectorFlipbook(emitter.affectors[i].cfg);
		}
	}
}