export let availableAtlases = [
    "pfx/pfx-alpha/pfx-alpha",
    "pfx/pfx-multi/pfx-multi",
    "pfx/projectile/projectile",
    "pfx/enemy_projectile/enemy_projectile",
    "pfx/player_projectile/player_projectile",
    "pfx/xp_projectile/xp_projectile",
	"pfx/fire200/fire200",
	"pfx/axe100/axe100",
	"pfx/spell200/spell200",
	"pfx/spell300/spell300",
	"pfx/longSpell300/longSpell300",
	"pfx/shield/shield",
	"pfx/dust/dust",
	"pfx/aoe-explosion/aoe-explosion",
	"pfx/poison/poison",
	"pfx/charge/charge",
	"pfx/shapes/shapes",
	"pfx/arrow/arrow",
	"pfx/explosion_fire/explosion_fire",
	"pfx/explosion_ice/explosion_ice",
	"pfx/explosion_lightning/explosion_lightning",
	"pfx/explosion_poison/explosion_poison",
	"pfx/hit_fire/hit_fire",
	"pfx/hit_ice/hit_ice",
	"pfx/hit_lightning/hit_lightning",
	"pfx/hit_physical/hit_physical",
	"pfx/hit_poison/hit_poison",
	"pfx/enemy_projectile_combined/enemy_projectile_combined",
	"pfx/bosseffects/bosseffects",
	"pfx/player-projectile-combined/player-projectile-combined",
	"pfx/bosseffects/prismbosseffects",
	"pfx/bosseffects/highlandsbosseffects",
	"pfx/damage-numbers/damage-numbers",
	"pfx/bosseffects/forestbosseffects",
	"pfx/explosion_blood/explosion_blood",
	"pfx/uieffects/uieffects",
	"pfx/status/armor_break",
	"pfx/enemy-projectiles/enemy-projectile",
	"pfx/combined/combined",
	"pfx/aoe-explosion/aoe-explosion-element",
	"pfx/bosseffects/highlandsbossstonewave",
	"pfx/hotcakes-combined/hotcakes-combined",
	"pfx/aoe-explosion/aoe-explosion-white",
	"pfx/nikolas-scope/nikolas-scope",
	"pfx/status-effects/common-status-effects",
	"pfx/weapon-projectiles/cannon/cannon-projectiles",
	"pfx/bigger-explosion/bigger-explosion",
	"pfx/weapon-projectiles/cannon/cannon-explosions",
]