

































import { mapGetters } from 'vuex'
import { Chrome } from 'vue-color'
import { ColorPropertyMode } from '../../pfx/emitterConfig'
import { pfx2vue, safeParseFloat, deg2rad, rad2deg, formatNumber } from '../../utils'

export default {
	name: 'PropEditorColor',
    components: {
		Chrome,
    },
    props: {
        onchange: Function, 
        prop: Object,
        canDisable: Boolean,
        modechanged: Function,
        inner: {
            type: Boolean,
            default: false
        },
    },
    enums: {
       ColorPropertyMode 
    },
    data: function() {
        return {
            disabled: this.prop.mode == ColorPropertyMode.Disabled
        }
    },
    methods: {
        triggerChange: function() {
            this.onchange()
        }
    },
	computed: {
       mode: {
			get() { 
                return this.prop.mode; 
            },
			set(newVal) { 
                if(this.modechanged) 
                    this.modechanged(this.prop.mode, newVal);
                this.prop.mode = newVal; 
                this.disabled = newVal == ColorPropertyMode.Disabled;
                this.triggerChange(); 
            }
        },
        randMin: {
            get() { 
                // Convert from pfx format to vue-color
                return pfx2vue(this.prop.min);
            },
            set(newVal:any) 
            { 
                // Convert from vue-color to pfx format
                this.prop.min.r = newVal.rgba.r / 255.0;
                this.prop.min.g = newVal.rgba.g / 255.0;
                this.prop.min.b = newVal.rgba.b / 255.0;
                this.prop.min.a = newVal.rgba.a;
                this.triggerChange(); 
            }
        },
        randMax: {
            get() { 
                // Convert from pfx format to vue-color
                return pfx2vue(this.prop.max);
            },
            set(newVal:any) { 
                // Convert from vue-color to pfx format
                this.prop.max.r = newVal.rgba.r / 255.0;
                this.prop.max.g = newVal.rgba.g / 255.0;
                this.prop.max.b = newVal.rgba.b / 255.0;
                this.prop.max.a = newVal.rgba.a;
                this.triggerChange(); 
            }
        },
        val: {
            get() { 
                // Convert from pfx format to vue-color
                return pfx2vue(this.prop.val);
            },
            set(newVal:any) { 
                // Convert from vue-color to pfx format
                this.prop.val.r = newVal.rgba.r / 255.0;
                this.prop.val.g = newVal.rgba.g / 255.0;
                this.prop.val.b = newVal.rgba.b / 255.0;
                this.prop.val.a = newVal.rgba.a;
                this.triggerChange(); 
            }
        },
	}
}
