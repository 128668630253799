import * as PIXI from "pixi.js"
window.PIXI = PIXI
import 'pixi-spine'
import { Viewport } from 'pixi-viewport'
import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import Vuesax from 'vuesax'
// import 'vuesax/dist/vuesax.css'
import 'material-icons/iconfont/material-icons.css';
import UIParent from './components/ui-parent.vue'
import { EmitterConfig, Vec2PropertyMode, Vec2PropConfig, FloatPropConfig, FloatPropertyMode, ColorPropertyMode, ColorPropConfig, ScaleAffectorConfig, FadeAffectorConfig, RotateAffectorConfig, ColorBlendAffectorConfig, FlipbookAffectorConfig } from './pfx/emitterConfig'
import { defaultEffect } from "./default-emitter"
import enums from 'vue-enums'
import { Emitter } from "./pfx/emitter"
import { patchEmitter } from "./sanitize-pfx"
import { availableAtlases } from "./atlases"
import { availableSprites } from "./sprites"
import { getEffect, createEffect, pfxCamera, initView, renderView } from "./pfx-view"
import { Effect } from "./pfx/effect"
import { EffectConfig, CURRENT_EFFECT_VERSION } from "./pfx/effectConfig"
import { getCookie, setCookie } from "./utils"
import { InstancedSpriteBatcher } from "./pfx/instanced-sprite-batcher"
import { RenderQueue } from "./render-queue"

const COOKIE_EFFECT_NAME = "soti-effect"
const FILE_CHANGE_PORTS = [7777, 7778] //hub,adventure

const fileChangeSockets = {}

Vue.config.productionTip = false

Vue.use(enums)
Vue.use(Vuex)
Vue.use(Vuesax, {
	// options here
})

export interface UIStateStructure {
	emissionShape: string;
	effectCfg: EffectConfig;
	currentEmitterIdx: number;
	resetNeeded: boolean;
	atlasFrames: string[];
	atlases: string[];
	sprites: string[];
	spriteSlots: string[];
	spriteAnims: string[];
	spriteSkins: string[];
	followMouse: boolean;
	isolateEmitter: boolean;
	bgColor: { r: number, g: number, b: number, a: number }
	lastCookieSaved: number;
	importError: string;
	currSlotName: string;
	currAnimName: string;
	currSkinName: string;
}

const initialUIState: UIStateStructure = {
	effectCfg: defaultEffect,
	emissionShape: "point",
	resetNeeded: false,
	atlasFrames: [],
	atlases: availableAtlases,
	sprites: availableSprites,
	spriteSlots: [],
	spriteAnims: [],
	spriteSkins: [],
	currentEmitterIdx: 0,
	followMouse: false,
	isolateEmitter: false,
	bgColor: { r: 0, g: 0, b: 0, a: 1 },
	lastCookieSaved: 0,
	importError: null,
	currSlotName: "<none>",
	currAnimName: null,
	currSkinName: null
}

function detachFromBone(boneName: string) {
	const effect = getEffect()
	if (effect) {
		currSpineSprite.detachPfx(boneName);
		effect.rot = 0
		effect.scale = 1
		effect.x = 0
		effect.y = 0
	}
}

const storeConfig: StoreOptions<UIStateStructure> = {

	// giving it the initial state...
	state: initialUIState,

	// ... next, declare all of the **state accessors** that the components will reference
	// (called "getters" in vuex nomenclature)
	getters: {
		// ...
		atlasFrames: (state) => {
			return state.atlasFrames
		},
		atlases: (state) => {
			return state.atlases
		},
		sprites: (state) => {
			return state.sprites
		},
		spriteSlots: (state) => {
			return state.spriteSlots
		},
		spriteAnims: (state) => {
			return state.spriteAnims
		},
		spriteSkins: (state) => {
			return state.spriteSkins
		},
		currentEmitterIdx: (state) => {
			return state.currentEmitterIdx
		},
		currentSlotName: (state) => {
			return state.currSlotName
		},
		currentAnimName: (state) => {
			return state.currAnimName
		},
		currentSkinName: (state) => {
			return state.currSkinName
		},
		effectCfg: (state) => {
			return state.effectCfg
		},
		importError: (state) => {
			return state.importError
		}
	},

	// ... next, declare all of the synchronous **potential mutations to state**
	mutations: {
		effectChanged(state: UIStateStructure, cfg: EffectConfig) {
			const currEffect = getEffect();
			if (currEffect) {
				currEffect.removeFromContainer();
			}

			state.currentEmitterIdx = 0;
			state.effectCfg = cfg;

			setCookie(COOKIE_EFFECT_NAME, JSON.stringify(cfg));

			createEffect(cfg, (effect) => {
				console.log('createEffect')
				initUIForEffect(effect, vm.$store.state.currentEmitterIdx);

				if (attached && currSpineSprite) {
					const slotName = state.currSlotName;
					state.currSlotName = null; // force it to change
					vm.$store.commit('attachSlot', slotName);
				}
			})
		},
		refreshEffect(state: UIStateStructure) {
			state.resetNeeded = true;
			let newEffectCfg = JSON.parse(JSON.stringify(state.effectCfg))
			let idxToRestore = state.currentEmitterIdx;
			vm.$store.commit('effectChanged', newEffectCfg);
			state.currentEmitterIdx = idxToRestore;
		},
		importError(state: UIStateStructure, error: string) {
			state.importError = error;
		},
		emitterAdded(state: UIStateStructure, emitter: EmitterConfig) {
			patchEmitter(emitter);
			state.effectCfg.emitters.push(emitter);
			let newEffectCfg = JSON.parse(JSON.stringify(state.effectCfg))
			vm.$store.commit('effectChanged', newEffectCfg);
			state.currentEmitterIdx = state.effectCfg.emitters.length - 1;
		},
		emitterRemoved(state: UIStateStructure, emitter: EmitterConfig) {
			let idx = state.effectCfg.emitters.indexOf(emitter)
			let newEffectCfg = JSON.parse(JSON.stringify(state.effectCfg))
			newEffectCfg.emitters.splice(idx, 1);
			vm.$store.commit('effectChanged', newEffectCfg);
		},
		emitterChanged(state: UIStateStructure) {
			//if(performance.now() - state.lastCookieSaved > 500) {
			setCookie(COOKIE_EFFECT_NAME, JSON.stringify(state.effectCfg));
			state.lastCookieSaved = performance.now()
			//}	
			window.dispatchEvent(new CustomEvent("pfxEditorValuesChanged", { detail: state }))
		},
		followMouseChanged(state: UIStateStructure, newVal: boolean) {
			state.followMouse = newVal;
		},
		isolateEmitterChanged(state: UIStateStructure, newVal: boolean) {
			state.isolateEmitter = newVal;
			let effect = getEffect();
			if (effect) {
				if (!newVal) {
					for (let i = 0; i < effect.emitters.length; ++i) {
						effect.emitters[i].visible = true
					}
				} else {
					for (let i = 0; i < effect.emitters.length; ++i) {
						effect.emitters[i].visible = i == state.currentEmitterIdx;
					}
				}
			}
		},
		emitterIdxChanged(state: UIStateStructure, emitterIdx: number) {
			state.currentEmitterIdx = emitterIdx
			let atlas = getEffect().emitters[emitterIdx].getAtlas();
			let frames = [];

			atlas.regions.forEach((entry) => {
				frames.push(entry.name);
			})

			vm.$store.commit('atlasFramesChanged', frames)

			// Force this to update what can be seen
			vm.$store.commit('isolateEmitterChanged', state.isolateEmitter)
		},
		resetNeeded(state: UIStateStructure) {
			state.resetNeeded = true;
		},
		atlasFramesChanged(state: UIStateStructure, frames) {
			frames = frames.sort()
			state.atlasFrames = frames
		},
		nameChanged(state: UIStateStructure, name) {
			state.effectCfg.name = name
		},
		newBGColor(state: UIStateStructure, bgColor) {
			state.bgColor = bgColor;
		},
		switchSprite(state: UIStateStructure, newSpriteName: string) {
			// Since we are about to switch sprite, if we were previously attached to 
			// something, remove it
			if (attached) {
				detachFromBone(state.currSlotName);
				attached = false;
			}
			state.currSlotName = "<none>";

			const sprite = spineSprites.get(newSpriteName);
			if (currSpineSprite)
				spriteLayer.removeChildAt(0);
			spriteLayer.addChild(sprite)
			currSpineSprite = sprite;

			let slots = ["<none>"]
			sprite.skeleton.slots.forEach((slot, idx) => {
				slots.push(slot.data.name)
			})
			state.spriteSlots = slots;

			let anims = []
			sprite.skeleton.data.animations.forEach((anim, idx) => {
				anims.push(anim.name)
			})
			state.spriteAnims = anims;
			state.currAnimName = anims[0];

			let skins = []
			sprite.skeleton.data.skins.forEach((skin, idx) => {
				skins.push(skin.name)
			})
			state.spriteSkins = skins;
			state.currSkinName = skins[0];
		},
		removeSprite(state: UIStateStructure) {
			if (state.currSlotName !== "<none>")
				detachFromBone(state.currSlotName)

			spriteLayer.removeChildAt(0);
			state.spriteSlots = []
			state.spriteAnims = []
			state.spriteSkins = []

			if (state.currSlotName !== "<none>") {
				detachFromBone(state.currSlotName);
				attached = false
			}

			currSpineSprite = null
		},
		attachSlot(state: UIStateStructure, newSlotName: string) {
			if (newSlotName === state.currSlotName)
				return

			if (state.currSlotName !== "<none>") {
				detachFromBone(state.currSlotName);
				attached = false
			}

			if (newSlotName !== "<none>") {
				currSpineSprite.attachPfx(newSlotName, getEffect());
				attached = true
			}

			state.currSlotName = newSlotName
		},
		spriteAnimChanged(state: UIStateStructure, newAnimName: string) {
			state.currAnimName = newAnimName
			if (currSpineSprite) {
				currSpineSprite.state.setAnimation(0, newAnimName, true)
			}
		},
		spriteSkinChanged(state: UIStateStructure, newSkinName: string) {
			state.currSkinName = newSkinName
			if (currSpineSprite) {
				currSpineSprite.skeleton.setSkinByName(newSkinName)
			}
		},
	},
}

// Initialize it with the aforementioned config
const state = new Vuex.Store<UIStateStructure>(storeConfig)

// Init vue!
let vm = new Vue({
	// Pass it our vuex store (this is what tells it this project is using vuex for state mgmt)
	store: state,

	// Render the "top level" component that is the parent of the project
	render: (h) => h(UIParent),
}).$mount('#ui-overlay')

function initUIForEffect(effect: Effect, emitterIdx = 0) {
	let atlas = effect.emitters[emitterIdx].getAtlas();
	let frames = [];

	atlas.regions.forEach((entry) => {
		frames.push(entry.name);
	})

	vm.$store.commit('emitterIdxChanged', emitterIdx)
	vm.$store.commit('atlasFramesChanged', frames)
}

export function setNewEffectFromConfig(cfg: EffectConfig) {
	vm.$store.commit('effectChanged', cfg)
}

export function setImportError(error: string) {
	vm.$store.commit('importError', error)
}


const canvas: HTMLCanvasElement = document.getElementById('main-canvas') as HTMLCanvasElement

const pixiRenderer = PIXI.autoDetectRenderer({
	width: canvas.clientWidth,
	height: canvas.clientHeight,
	view: canvas,
	antialias: false,
	// backgroundColor: 0x111111,
	backgroundColor: 0xff0000,
	transparent: false,
	resolution: 1,
})

// create viewport
const viewport = new Viewport({
	screenWidth: canvas.clientWidth,
	screenHeight: canvas.clientHeight,
	worldWidth: canvas.clientWidth,
	worldHeight: canvas.clientHeight,

	interaction: pixiRenderer.plugins.interaction // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
})

initView(pixiRenderer);
viewport.drag()

let editorLayer = new PIXI.Container()
let spriteLayer = new PIXI.Container()
let pfxLayer = new PIXI.Container()
let effectOrigin: PIXI.Sprite;
let emitterOrigin: PIXI.Sprite;
let spineSprites: Map<string, PIXI.spine.Spine> = new Map();
let currSpineSprite: PIXI.spine.Spine = null;
let attached = false
let mouseX: number = 0;
let mouseY: number = 0;

viewport.name = "viewport"
editorLayer.name = 'editorLayer'
spriteLayer.name = 'spriteLayer'
pfxLayer.name = 'pfxLayer'
viewport.addChild(editorLayer);
editorLayer.addChild(spriteLayer);

window.addEventListener('resize', () => {
	if (pixiRenderer) {
		pixiRenderer.resize(canvas.clientWidth, canvas.clientHeight)
		const style = getComputedStyle(canvas.parentNode)
		const width = parseInt(style.getPropertyValue('width'), 10)
		const height = parseInt(style.getPropertyValue('height'), 10)
		viewport.screenWidth = width
		viewport.screenHeight = height
		viewport.worldWidth = width
		viewport.worldHeight = height
		emitterOrigin.position.set(canvas.clientWidth / 2.0, canvas.clientHeight / 2.0)
		effectOrigin.position.set(canvas.clientWidth / 2.0, canvas.clientHeight / 2.0)
		console.log(width)
	}
})

canvas.addEventListener('mousemove', e => {
	mouseX = e.clientX;
	mouseY = e.clientY;
});

function update(delta, now): void {
	Effect.forceWorldSpace = !attached
	viewport.update(delta)

	pfxCamera.x = viewport.x;
	pfxCamera.y = viewport.y;
	pfxCamera.halfWidth = canvas.clientWidth / 2.0
	pfxCamera.halfHeight = canvas.clientHeight / 2.0
	pfxCamera.zoom = viewport.scale.x

	const effect = getEffect()
	if (effect) {

		if (!attached) {
			if (vm.$store.state.followMouse) {
				effect.x = mouseX;
				effect.y = mouseY;
			} else {
				effect.x = canvas.clientWidth / 2.0
				effect.y = canvas.clientHeight / 2.0
			}
		}

		effect.update(delta);

		if (emitterOrigin) {
			const emitter = effect.emitters[vm.$store.state.currentEmitterIdx];
			if (!emitter)
				debugger
			emitterOrigin.position.set(effectOrigin.x + emitter.ofsX, effectOrigin.y + emitter.ofsY);
		}
	}

	spineSprites.forEach((spr, name) => {
		spr.update(delta);
	})

	let bgColor = vm.$store.state.bgColor;
	pixiRenderer.backgroundColor = PIXI.utils.rgb2hex([bgColor.r, bgColor.g, bgColor.b]);
	pixiRenderer.render(viewport);
	renderView();
}

function initializeEditor() {
	let previous = performance.now()

	Emitter.EnablePerformanceReporting();
	Effect.forceWorldSpace = true;

	let initialEffectCfg = defaultEffect;
	if (getCookie(COOKIE_EFFECT_NAME)) {
		let cookie = getCookie(COOKIE_EFFECT_NAME);
		let effectCfg = JSON.parse(cookie)
		let version = effectCfg.version
		if (version === CURRENT_EFFECT_VERSION) {
			console.log("Found, and using, effect found in cookie: ", effectCfg.version, version);
			initialEffectCfg = effectCfg;
		}
		else {
			console.log("Effect version in cookie is too old. Ignoring.");
		}
	} else {
		console.log("No effect in cookie, using built-in default");
	}

	initialEffectCfg.emitters.forEach((emitter) => {
		patchEmitter(emitter);
	})
	vm.$store.commit('effectChanged', initialEffectCfg)

	let editorGfxLoader = new PIXI.Loader();
	editorGfxLoader.add('origin.png')

	editorGfxLoader.load((loader, resources) => {
		effectOrigin = new PIXI.Sprite(resources['origin.png'].texture)
		effectOrigin.pivot.set(64, 64)
		effectOrigin.position.set(canvas.clientWidth / 2.0, canvas.clientHeight / 2.0)

		emitterOrigin = new PIXI.Sprite(resources['origin.png'].texture)
		emitterOrigin.pivot.set(64, 64)
		emitterOrigin.position.set(canvas.clientWidth / 2.0, canvas.clientHeight / 2.0)
		emitterOrigin.tint = 0x777777

		editorLayer.addChild(effectOrigin)
		editorLayer.addChild(emitterOrigin)
	})

	let spritesLoader = new PIXI.Loader();
	for (let i = 0; i < availableSprites.length; ++i) {
		spritesLoader.add(availableSprites[i])
	}

	spritesLoader.load((loader, resources) => {
		const sprites = Object.keys(resources);
		sprites.forEach((path) => {
			if (path.endsWith(".json")) {
				const asset = resources[path]
				const sprite = new PIXI.spine.Spine(asset.spineData)
				sprite.scale.x = 1
				sprite.scale.y = 1
				sprite.skeleton.setToSetupPose()
				sprite.update(0)
				sprite.autoUpdate = false
				sprite.state.setAnimation(0, sprite.skeleton.data.animations[0].name, true)
				sprite.position.set(canvas.clientWidth / 2.0, canvas.clientHeight / 2.0)

				spineSprites.set(path, sprite);

				// editorLayer.addChild(sprite)

				// sprite.skeleton.slots.forEach( (slot) => {
				// 	console.log(slot.data.name);
				// })
			}
		})
	})

	const loop = function () {
		const now = performance.now()
		const delta = (now - previous) / 1000
		previous = now

		window.requestAnimationFrame(loop)

		update(delta, now)
	}

	loop()
}

window.onload = function () {
	const atlases = availableAtlases.map((atlas) => {
		return { atlas: atlas + ".atlas", atlasTexture: atlas + ".png" }
	})

	Effect.PreloadAtlases(atlases, () => {
		initializeEditor();
	})
}

window.addEventListener('pfxEditorValuesChanged', (newPfxEditorValues: CustomEvent) => {
	const uiCfg = newPfxEditorValues.detail as UIStateStructure;

	// console.log("Switching config to ", JSON.stringify(uiCfg,undefined,2));
	//debugger;

	let currEmitterIdx = vm.$store.state.currentEmitterIdx;
	getEffect().setEmitterConfig(currEmitterIdx, uiCfg.effectCfg.emitters[currEmitterIdx], uiCfg.resetNeeded);

	// send updated asset to game, if running
	sendEffectToGame(uiCfg)

	if (uiCfg.resetNeeded) {
		getEffect().whenReady((effect) => {
			initUIForEffect(effect, vm.$store.state.currentEmitterIdx);
		})
	}

	uiCfg.resetNeeded = false;
})

function sendEffectToGame(uiCfg: UIStateStructure) {
	FILE_CHANGE_PORTS.forEach(port => {
		const url = `ws://localhost:${port}`
		if (fileChangeSockets[port] === undefined) {
			fileChangeSockets[port] = new WebSocket(url)
		}
		const ws = fileChangeSockets[port] as WebSocket

		if (ws.readyState === ws.OPEN) {
			sendEffectOnSocket(uiCfg.effectCfg, url, ws)
		}
		else {
			ws.onopen = (ev) => {
				sendEffectOnSocket(uiCfg.effectCfg, url, ws)
			}
		}
	})
}

function sendEffectOnSocket(effect: EffectConfig, url: string, ws: WebSocket) {
	console.log(`sending updated effect:${effect.name}  to: ${url}`)
	const data = JSON.stringify(effect)
	ws.send(JSON.stringify({ id: effect.name, data }))
}

