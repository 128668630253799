


























































import { mapGetters } from "vuex";
import { getEffect } from "../pfx-view";
import { defaultEmitter } from "../default-emitter";
import { importEffect } from "../import-pfx"
import { exportEffect } from '../export-pfx';
import { deleteCookie } from '../utils';

export default {
  name: "EffectPanel",
  components: {},
  props: [],
  data() {
    return {
      emitters: [],
      nextKey: 0
    };
  },
  watch: {
    effectCfg: function(newVal, oldVal) {
      console.log("NEW EFFECT DETECTED");
      if (newVal != oldVal) {
        this.initVals();
      }
    }
  },
  methods: {
    triggerChange: function() {},
    initVals: function() {
      this.emitters = [];
      for (let i = 0; i < this.effectCfg.emitters.length; ++i) {
        this.emitters.push({
          name: this.effectCfg.emitters[i].name,
          key: this.nextKey
        });
        this.nextKey = this.nextKey + 1;
      }
    },
    saveAsFile: function(link, filename) {
        this.effectCfg.name = this.name;
        let content = exportEffect(this.effectCfg);
        var json = JSON.stringify(content)
        var blob = new Blob([json], {type: "application/json"});
        var url  = URL.createObjectURL(blob);

        // update link to new 'url'
        link.download    = filename + ".json";
        link.href        = url;
    },
    downloadClicked: function(event) {
        this.saveAsFile(this.$refs.download, this.name)
    },
    newClicked: function(event) {
        this.$vs.dialog({
            type:'confirm',
            color: 'danger',
            title: 'Confirm',
            text: 'Are you sure you want to create a new effect and lose this one to the ether, forever?',
            accept: this.acceptNew
        })
    },
    acceptNew: function() {
        deleteCookie("soti-effect")
        location.reload()
    },
    filesChange: function(fieldName, fileList) {
        var reader = new FileReader();
        reader.onload = function(){
            importEffect(reader.result as string);
        };
        reader.readAsText(fileList[0]);
    },
    addNewClicked: function(event) {
      let newEmitter = JSON.parse(JSON.stringify(defaultEmitter));
      newEmitter.name = "new-default-" + this.effectCfg.emitters.length;
      this.$store.commit("emitterAdded", newEmitter);
      this.triggerChange();
    },
    copyCurrentClicked: function(event) {
      let newEmitter = JSON.parse(
        JSON.stringify(this.effectCfg.emitters[this.currentEmitterIdx])
      );
      newEmitter.name += "-" + this.effectCfg.emitters.length;
      this.$store.commit("emitterAdded", newEmitter);
      this.triggerChange();
    },
    deleteCurrentClicked: function(event) {
        this.$vs.dialog({
            type:'confirm',
            color: 'danger',
            title: `Confirm`,
            text: 'Come on now, are you sure you want to delete this emitter?',
            accept: this.acceptAlert
        })
    },
    upClicked: function(event) {
      const effectCfg = this.effectCfg;
      if(effectCfg.emitters.length > 1 && this.currentEmitterIdx < effectCfg.emitters.length - 1) {
        const origIdx = this.currentEmitterIdx;
        const emitter = effectCfg.emitters[origIdx];
        effectCfg.emitters.splice(origIdx, 1);
        effectCfg.emitters.splice(origIdx + 1, 0, emitter);

        this.emitterIdx = origIdx + 1;
        this.$store.commit("refreshEffect");
        this.$store.commit("emitterIdxChanged", this.emitterIdx);
        this.initVals();
      }
    },
    downClicked: function(event) {
      const effectCfg = this.effectCfg;
      if(effectCfg.emitters.length > 1 && this.currentEmitterIdx > 0) {
        const origIdx = this.currentEmitterIdx;
        const emitter = effectCfg.emitters[origIdx];
        effectCfg.emitters.splice(origIdx, 1);
        effectCfg.emitters.splice(origIdx - 1, 0, emitter);

        this.$store.commit("refreshEffect");
        this.initVals();
        this.emitterIdx = origIdx - 1;
      }
    },
    acceptAlert: function(){
      this.$store.commit(
        "emitterRemoved",
        this.effectCfg.emitters[this.currentEmitterIdx]
      );
      this.triggerChange();
    },
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  mounted() {
    this.initVals();
    setInterval( () => {
        if(this.$refs.menuCollapse) {
            this.$refs.menuCollapse.$children.forEach( (item) => {
                item.changeHeight(); 
            })
        }
    }, 50)
  },
  computed: {
    ...mapGetters(["currentEmitterIdx", "effectCfg", "importError"]),
    emitterIdx: {
      get() {
        return this.currentEmitterIdx;
      },
      set(newVal: number) {
        this.$store.commit("emitterIdxChanged", newVal);
      }
    },
    canDelete: {
      get() {
        return this.effectCfg.emitters.length > 1;
      }
    },
    name: {
        get() {
            return this.$store.state.effectCfg.name;
        },
        set(newValueFromInput:string) {
            this.$store.commit("nameChanged", newValueFromInput)
        }
    },
  }
};
