





































import { mapGetters } from 'vuex'
import { Compact } from 'vue-color'
import { pfx2vue } from '../utils'

export default {
	name: 'SpineMenu',
	components: {
		Compact,
	},
	props: [],
	data () {
		return {
            currSprite: "<none>",
		}
	},
	methods: {
	},
	beforeDestroy () {
	},
	created () {
	},
	computed: {
		...mapGetters([
			'sprites',
            'spriteSlots',
            'spriteAnims',
            'spriteSkins',
            'currentSlotName',
            'currentAnimName',
            'currentSkinName'
		]),
		sprite: {
			get() {
				return this.currSprite;	
			},
			set(newValueFromInput:string) {
                this.currSprite = newValueFromInput;
                if(this.currSprite === "<none>") {
                    this.$store.commit("removeSprite")
                } else {
                    this.$store.commit("switchSprite", this.currSprite)
                }
			}
		},
		slot: {
			get() {
				return this.currentSlotName;
			},
			set(newValueFromInput:string) {
                this.$store.commit("attachSlot", newValueFromInput)
			}
		},
		anim: {
			get() {
				return this.currentAnimName;
			},
			set(newValueFromInput:string) {
                this.$store.commit("spriteAnimChanged", newValueFromInput)
			}
		},
		skin: {
			get() {
				return this.currentSkinName;
			},
			set(newValueFromInput:string) {
                this.$store.commit("spriteSkinChanged", newValueFromInput)
			}
		},
	},
}
