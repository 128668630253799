import {setNewEffectFromConfig, setImportError} from "./index"
import {patchEmitter} from "./sanitize-pfx"
import { CURRENT_EFFECT_VERSION} from "./pfx/effectConfig"

export function importEffect(fileContents:string) {
    let effectCfg = JSON.parse(fileContents);
    if(effectCfg.version !== CURRENT_EFFECT_VERSION) {
        setImportError("Version being loaded is too old - give it to a SOTI programmer to fix")
    } else {
        setImportError(null)
        effectCfg.emitters.forEach((emitter)=> {
            patchEmitter(emitter);
        })
        setNewEffectFromConfig(effectCfg);
    }
}