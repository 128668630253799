




















import EditorPane from "./editor-pane.vue";
import PropEditorVec2 from "./property-editors/proped-vec2.vue";
import PropEditorFloat from "./property-editors/proped-float.vue";
import PropEditorColor from "./property-editors/proped-color.vue";
import PropEditorAffectorScale from "./property-editors/proped-aff-scale.vue";
import PropEditorAffectorFlipbook from "./property-editors/proped-aff-flipbook.vue";
import PropEditorEmissionShape from "./property-editors/proped-emission-shape.vue";
import PropEditorFrames from "./property-editors/proped-frames.vue";
import PropEditorAnims from "./property-editors/proped-anims.vue";
import DebugInfoPanel from "./dbg-info-panel.vue";
import EffectPanel from "./effect-panel.vue";
import SettingsMenu from "./settings-menu.vue";
import SpineMenu from "./spine-menu.vue";
import PropEditorEmissionMode from "./property-editors/proped-emission-mode.vue";
import { mapGetters } from "vuex";

export default {
  name: "UIParent",
  components: {
    EditorPane,
    DebugInfoPanel,
    PropEditorVec2,
    PropEditorFloat,
    PropEditorColor,
    PropEditorAffectorScale,
    PropEditorAffectorFlipbook,
    PropEditorEmissionShape,
    PropEditorFrames,
    PropEditorAnims,
    PropEditorEmissionMode,
    EffectPanel,
    SettingsMenu,
    SpineMenu,
  },
  methods: {
    testme: (val) => {
      console.log("GOT CHANGES!", JSON.stringify(val, undefined, 2));
    },
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters(["currentEmitterIdx"]),
    emitterIdx: {
      get() {
        return this.currentEmitterIdx;
      },
      set(newVal: any) {
        this.$store.commit("emitterIdxChanged", newVal);
      },
    },
  },
};
