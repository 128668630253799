import { EmitterConfig, EmissionShape, FloatPropConfig, FloatPropertyMode, ColorPropConfig, ColorPropertyMode, Vec2PropConfig, Vec2PropertyMode, ScaleAffectorConfig, RotateAffectorConfig, FadeAffectorConfig, ColorBlendAffectorConfig, FlipbookAffectorConfig, ScaleAffectorMode, RotateAffectorMode, FadeAffectorMode, ColorBlendAffectorMode, FlipbookAffectorMode, EmissionMode } from "./pfx/emitterConfig";
import { EffectConfig } from "./pfx/effectConfig";

function pruneFloatProp(prop:FloatPropConfig) {
    switch(prop.mode) {
        case FloatPropertyMode.Disabled:
            delete prop.min;
            delete prop.max;
            delete prop.val;
            break;
        case FloatPropertyMode.Constant:
            delete prop.min;
            delete prop.max;
            break;
        case FloatPropertyMode.Random:
            delete prop.val;
            break;
    }
}

function pruneColorProp(prop:ColorPropConfig) {
    switch(prop.mode) {
        case ColorPropertyMode.Disabled:
            delete prop.min;
            delete prop.max;
            delete prop.val;
            break;
        case ColorPropertyMode.Constant:
            delete prop.min;
            delete prop.max;
            break;
        case ColorPropertyMode.Random:
            delete prop.val;
            break;
    }
}

function pruneVec2Prop(prop:Vec2PropConfig) {
    switch(prop.mode) {
        case Vec2PropertyMode.Disabled:
            delete prop.min;
            delete prop.max;
            delete prop.val;
            break;
        case Vec2PropertyMode.Constant:
            delete prop.min;
            delete prop.max;
            break;
        case Vec2PropertyMode.Random:
            delete prop.val;
            break;
    }
}

function pruneEmissionShape(emitter:EmitterConfig) {
    switch(emitter.shape) {
        case EmissionShape.Point:
            delete emitter.boxEmitter;
            delete emitter.circleEmitter;
            break;
        case EmissionShape.Box:
            delete emitter.circleEmitter;
            pruneFloatProp(emitter.boxEmitter.width);
            pruneFloatProp(emitter.boxEmitter.height);
            break;
        case EmissionShape.Circle:
            delete emitter.boxEmitter;
            break;
    }
}

function pruneEmissionMode(emitter:EmitterConfig) {
    switch(emitter.emissionMode) {
        case EmissionMode.Continuous:
            delete emitter.burst;
            pruneFloatProp(emitter.continuous.spawnRate);
            break;
        case EmissionMode.Burst:
            delete emitter.continuous;
            pruneFloatProp(emitter.burst.numParticles);
            pruneFloatProp(emitter.burst.timeBetweenBursts);
            break;
    }
}

function pruneAffectorScale(aff:ScaleAffectorConfig) {
    switch(aff.mode) {
        case ScaleAffectorMode.Disabled:
            delete aff.constSpeed;
            delete aff.target;
            delete aff.times;
            break;
        case ScaleAffectorMode.Constant:
            delete aff.target;
            delete aff.times;
            pruneFloatProp(aff.constSpeed);
            break;
        case ScaleAffectorMode.OverLife:
            delete aff.constSpeed;
            delete aff.times;
            pruneFloatProp(aff.target);
            break;
        case ScaleAffectorMode.InOutOverLife:
            delete aff.constSpeed;
            pruneFloatProp(aff.target);
            pruneFloatProp(aff.times[0]);
            pruneFloatProp(aff.times[1]);
            break;
    }
}

function pruneAffectorRotate(aff:RotateAffectorConfig) {
    switch(aff.mode) {
        case RotateAffectorMode.Disabled:
            delete aff.constSpeed;
            delete aff.target;
            delete aff.times;
            break;
        case RotateAffectorMode.Constant:
            delete aff.target;
            delete aff.times;
            pruneFloatProp(aff.constSpeed);
            break;
        case RotateAffectorMode.OverLife:
            delete aff.constSpeed;
            delete aff.times;
            pruneFloatProp(aff.target);
            break;
        case RotateAffectorMode.InOutOverLife:
            delete aff.constSpeed;
            pruneFloatProp(aff.target);
            pruneFloatProp(aff.times[0]);
            pruneFloatProp(aff.times[1]);
            break;
    }
}

function pruneAffectorFade(aff:FadeAffectorConfig) {
    switch(aff.mode) {
        case FadeAffectorMode.Disabled:
            delete aff.constSpeed;
            delete aff.target;
            delete aff.times;
            break;
        case FadeAffectorMode.Constant:
            delete aff.target;
            delete aff.times;
            pruneFloatProp(aff.constSpeed);
            break;
        case FadeAffectorMode.OverLife:
            delete aff.constSpeed;
            delete aff.times;
            pruneFloatProp(aff.target);
            break;
        case FadeAffectorMode.InOutOverLife:
            delete aff.constSpeed;
            pruneFloatProp(aff.target);
            pruneFloatProp(aff.times[0]);
            pruneFloatProp(aff.times[1]);
            break;
    }
}

function pruneAffectorColorBlend(aff:ColorBlendAffectorConfig) {
    switch(aff.mode) {
        case ColorBlendAffectorMode.Disabled:
            delete aff.target;
            delete aff.times;
            delete aff.mid;
            break;
        case ColorBlendAffectorMode.OverLife:
            delete aff.times;
            delete aff.mid;
            pruneColorProp(aff.target);
            break;
        case ColorBlendAffectorMode.InOutOverLife:
            pruneColorProp(aff.target);
            pruneColorProp(aff.mid);
            pruneFloatProp(aff.times[0]);
            pruneFloatProp(aff.times[1]);
            break;
    }
} 
 
function pruneAffectorFlipbook(aff:FlipbookAffectorConfig) {
    switch(aff.mode) {
        case FlipbookAffectorMode.Disabled:
            delete aff.constSpeed;
            delete aff.holdFrame;
            delete aff.times;
            delete aff.loop;
            break;
        case FlipbookAffectorMode.Constant:
            delete aff.holdFrame;
            delete aff.times;
            pruneFloatProp(aff.constSpeed);
            break;
        case FlipbookAffectorMode.OverLife:
            delete aff.constSpeed;
            delete aff.times;
            delete aff.holdFrame;
            delete aff.loop;
            break;
        case FlipbookAffectorMode.OverLifeWithHold:
            delete aff.constSpeed;
            delete aff.loop;
            pruneFloatProp(aff.holdFrame);
            pruneFloatProp(aff.times[0]);
            pruneFloatProp(aff.times[1]);
            break;
    }
}

function pruneEmitter(emitter:EmitterConfig) {
	pruneEmissionShape(emitter);
	pruneEmissionMode(emitter);
    pruneFloatProp(emitter.startDelay.delayTime);
	pruneColorProp(emitter.color);
	pruneFloatProp(emitter.lifetime);
	pruneFloatProp(emitter.scale);
	pruneFloatProp(emitter.rot);
    pruneVec2Prop(emitter.velocity);

	for(let i = 0; i < emitter.affectors.length; ++i) {
		if(emitter.affectors[i].id == "scale") {
			pruneAffectorScale(emitter.affectors[i].cfg);
		} else if(emitter.affectors[i].id == "rotate") {
			pruneAffectorRotate(emitter.affectors[i].cfg);
		} else if(emitter.affectors[i].id == "fade") {
			pruneAffectorFade(emitter.affectors[i].cfg);
		} else if(emitter.affectors[i].id == "color") {
			pruneAffectorColorBlend(emitter.affectors[i].cfg);
		} else if(emitter.affectors[i].id == "flipbook") {
			pruneAffectorFlipbook(emitter.affectors[i].cfg);
		}
    }
}

function exportEmitter(emitter:EmitterConfig) {
    let emitterCopy = JSON.parse(JSON.stringify(emitter))
    pruneEmitter(emitterCopy);
    return emitterCopy;
}

export function exportEffect(effectCfg:EffectConfig) {
    // This is a bit dumb but it works and it's an internal tool
    let effectCopy = JSON.parse(JSON.stringify(effectCfg))
    effectCopy.emitters = []

    effectCfg.emitters.forEach( (emitter) => {
        effectCopy.emitters.push(exportEmitter(emitter));
    })

    return effectCopy;
}