import { Emitter } from "./pfx/emitter";
import { Effect } from "./pfx/effect";
import { EffectConfig } from "./pfx/effectConfig";
import { Container } from "pixi.js";
import { IParticleRendererCamera } from "./pfx/particle-renderer";
import { InstancedSpriteBatcher } from "./pfx/instanced-sprite-batcher";
import { RenderQueue } from "./render-queue";

let currentEffect:Effect = null;
let currentParent:Container = null;
let instancedSpriteBatcher;
let renderQueue;

// This isn't used in the editor but needs to be present
export const pfxCamera:IParticleRendererCamera = {
    x: 0,
    y: 0,
    halfWidth: 0,
    halfHeight: 0,
    zoom: 1.0
}

export function getEffect() {
    return currentEffect;
}

export function createEffect(cfg:EffectConfig, whenReady?:(effect:Effect)=>void) {
    if(currentEffect)
        currentEffect.removeFromContainer(currentParent);
    currentEffect = new Effect(cfg,pfxCamera)
    currentEffect.whenReady(whenReady)
}

export function initView(pixiRenderer:PIXI.Renderer) {
    // don't care about batches in the editor - 8 is minimum webgl supports, so go with that
    instancedSpriteBatcher = new InstancedSpriteBatcher(pixiRenderer, 8) 
    renderQueue = new RenderQueue(pixiRenderer, instancedSpriteBatcher)
}

export function renderView() {
    if(currentEffect) {
        renderQueue.clear()
        currentEffect.render(renderQueue)
        renderQueue.flush(pfxCamera)
	}
}