
















import { mapGetters } from 'vuex'
import { ForceAffectorMode, ForceAffectorConfig, Vec2PropertyMode } from '../../pfx/emitterConfig'
import PropEditorVec2 from "./proped-vec2.vue";
import { findAffector, removeAffector } from '../../utils'

export default {
	name: 'PropEditorAffectorForce',
    components: {
		PropEditorVec2,
    },
    props: {
        onchange: Function, 
        emitter: Object
    },
    enums: {
       ForceAffectorMode 
    },
    data: function () {
        return {
        }
    },
    methods: {
        triggerChange: function() {
            this.onchange();
        },
        createAffector: function(initialMode) {
            return {
                mode: initialMode,
                force: {
                    mode: Vec2PropertyMode.Constant,
                    val: { x: 0, y: 1000 },
                    min: { x: 0, y: 0 },
                    max: { x: 0, y: 0 }
                },
            }
        }
    },
	computed: {
        mode: {
            get() {
                let aff:ForceAffectorConfig = findAffector(this.emitter, "force");
                if(!aff)
                    return ForceAffectorMode.Disabled;
                return aff.mode;
            },
            set(newVal:number) {
                let aff:ForceAffectorConfig = findAffector(this.emitter, "force");
                if(!aff) {
                    aff = this.createAffector(newVal);
                    this.emitter.affectors.push({
                        id: "force",
                        cfg: aff
                    });
                } else {
                    if(newVal == ForceAffectorMode.Disabled) {
                        removeAffector(this.emitter, "force")
                    } else {
                        aff.mode = newVal;
                    }
                }

                this.triggerChange();
            }
        },
        force: {
            get() {
                let aff:ForceAffectorConfig = findAffector(this.emitter, "force");
                return aff.force;
            },
        },
	}
}
