import { EmitterConfig } from "./pfx/emitterConfig";

export function safeParseFloat(s:string) {
	const f = parseFloat(s);
	if(isNaN(f))
		return 0;
	return f;
}

export function deg2rad(deg:number) {
    return (deg / 180.0) * Math.PI;
}

export function rad2deg(rad:number) {
    return (rad / Math.PI) * 180.0;
}

export function formatNumber(num:number) {
    if(num === Math.floor(num)) {
        return num;
    } else {
        return num.toFixed(2);
    }
}

export function findAffector(emitter:EmitterConfig, name:string) {
    if(!emitter)
        return null;

    for(let i = 0; i < emitter.affectors.length; ++i) {
        if(emitter.affectors[i].id == name)
            return emitter.affectors[i].cfg;
    }
    return null;
}

export function removeAffector(emitter:EmitterConfig, name:string) {
    if(!emitter)
        return null;

    let idx = -1;
    for(let i = 0; i < emitter.affectors.length; ++i) {
        if(emitter.affectors[i].id == name) {
            idx = i;
            break;
        }
    }

    if(idx !== -1) {
        emitter.affectors.splice(idx, 1);
    }
}

export function pfx2vue(pfx) {
    return {
        r: Math.floor(pfx.r * 255),
        g: Math.floor(pfx.g * 255),
        b: Math.floor(pfx.b * 255),
        a: pfx.a
    }
}

export function setCookie(name: string, val: string) {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
}

export function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}

export function deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = `${name}=;expires=${date.toUTCString()};path=/`;
}
