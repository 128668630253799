



























import { mapGetters } from 'vuex'
import { ColorBlendAffectorMode, ColorBlendAffectorConfig, FloatPropertyMode } from '../../pfx/emitterConfig'
import PropEditorFloat from "./proped-float.vue";
import PropEditorColor from "./proped-color.vue";
import { findAffector, removeAffector } from '../../utils'

export default {
	name: 'PropEditorAffectorColorBlend',
    components: {
		PropEditorColor,
		PropEditorFloat,
    },
    props: {
        onchange: Function, 
        emitter: Object
    },
    enums: {
       ColorBlendAffectorMode 
    },
    methods: {
        triggerChange: function() {
            this.onchange();
        },
        createAffector: function(initialMode) {
            return {
                mode: initialMode,
                target: {
                    mode: FloatPropertyMode.Constant,
                    min: { r: 1, g: 1, b: 1, a: 1.0 },
                    max: { r: 1, g: 1, b: 1, a: 1.0 },
                    val: { r: 1, g: 1, b: 1, a: 1.0 },
                },
                mid: {
                    mode: FloatPropertyMode.Constant,
                    min: { r: 1, g: 1, b: 1, a: 1.0 },
                    max: { r: 1, g: 1, b: 1, a: 1.0 },
                    val: { r: 1, g: 1, b: 1, a: 1.0 },
                },
                times: [
                    {
                        mode: FloatPropertyMode.Constant,
                        val: 0.5,
                        min: 0,
                        max: 0
                    },
                    {
                        mode: FloatPropertyMode.Constant,
                        val: 0.5,
                        min: 0,
                        max: 0
                    }
                ]
            }
        }
    },
	computed: {
        mode: {
            get() {
                let aff:ColorBlendAffectorConfig = findAffector(this.emitter, "color");
                if(!aff)
                    return ColorBlendAffectorMode.Disabled;
                return aff.mode;
            },
            set(newVal:number) {
                let aff:ColorBlendAffectorConfig = findAffector(this.emitter, "color");
                if(!aff) {
                    aff = this.createAffector(newVal);
                    this.emitter.affectors.push({
                        id: "color",
                        cfg: aff
                    });
                } else {
                    if(newVal == ColorBlendAffectorMode.Disabled) {
                        removeAffector(this.emitter, "color")
                    } else {
                        aff.mode = newVal;
                    }
                }

                this.triggerChange(aff);
            }
        },
        target: {
            get() {
                let aff:ColorBlendAffectorConfig = findAffector(this.emitter, "color");
                return aff.target;
            }
        },
        mid: {
            get() {
                let aff:ColorBlendAffectorConfig = findAffector(this.emitter, "color");
                return aff.mid;
            }
        },
        times0: {
            get() {
                let aff:ColorBlendAffectorConfig = findAffector(this.emitter, "color");
                return aff.times[0];
            }
        },
        times1: {
            get() {
                let aff:ColorBlendAffectorConfig = findAffector(this.emitter, "color");
                return aff.times[1];
            }
        }
	}
}
