



































































































import { mapGetters } from "vuex";
import { Vec2PropertyMode } from "../../pfx/emitterConfig";
import { safeParseFloat, formatNumber } from "../../utils";

export default {
  name: "PropEditorVec2",
  components: {},
  props: {
    min: Object,
    max: Object,
    onchange: Function,
    prop: Object,
    canDisable: Boolean,
    inner: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  enums: {
    Vec2PropertyMode,
  },
  data: function () {
    return {
      disabled: this.prop.mode == Vec2PropertyMode.Disabled,
    };
  },
  methods: {
    triggerChange: function () {
      this.onchange();
    },
  },
  computed: {
    mode: {
      get() {
        return this.prop.mode;
      },
      set(newVal) {
        this.prop.mode = newVal;
        this.disabled = newVal == Vec2PropertyMode.Disabled;
        this.triggerChange();
      },
    },
    minX: {
      get() {
        return formatNumber(this.prop.min.x);
      },
      set(newVal: string) {
        this.prop.min.x = safeParseFloat(newVal);
        this.triggerChange();
      },
    },
    minY: {
      get() {
        return formatNumber(this.prop.min.y);
      },
      set(newVal: string) {
        this.prop.min.y = safeParseFloat(newVal);
        this.triggerChange();
      },
    },
    maxX: {
      get() {
        return this.prop.max.x;
      },
      set(newVal: string) {
        this.prop.max.x = safeParseFloat(newVal);
        this.triggerChange();
      },
    },
    maxY: {
      get() {
        return this.prop.max.y;
      },
      set(newVal: string) {
        this.prop.max.y = safeParseFloat(newVal);
        this.triggerChange();
      },
    },
    valX: {
      get() {
        return this.prop.val.x;
      },
      set(newVal: string) {
        this.prop.val.x = safeParseFloat(newVal);
        this.triggerChange();
      },
    },
    valY: {
      get() {
        return this.prop.val.y;
      },
      set(newVal: string) {
        this.prop.val.y = safeParseFloat(newVal);
        this.triggerChange();
      },
    },
  },
};
