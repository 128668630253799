





















import { mapGetters } from 'vuex'

export default {
	name: 'PropEditorAnim',
    components: {},
    props: {
        onchange: Function, 
        anim: Object,
        inner: {
            type: Boolean,
            default: false
        },
    },
    enums: {
    },
    methods: {
        triggerChange: function() {
            let collapsed = [];
            for(let i = 0; i < this.frames.length; ++i) {
                collapsed.push(this.frames[i].frame)
            }
            this.anim.frames = collapsed;
            this.onchange()
        },
        addClicked: function(event) {
            let val = this.atlasFrames[this.currVal];
            this.frames.push( {
                frame: val,
                key: this.nextKey
            })
            this.nextKey = this.nextKey + 1;
            this.triggerChange();
        },
        removeFrame: function(event, idx) {
            this.frames.splice(idx,1); 
            this.triggerChange();
        },
        addAllFrames: function(event) {
            this.frames = [];
            this.atlasFrames.forEach( (frame) => {
                this.frames.push( {
                    frame: frame,
                    key: this.nextKey
                })
                this.nextKey = this.nextKey + 1;
            })
            this.triggerChange();
        },
        removeAllFrames: function(event) {
            this.frames = [];
            this.triggerChange();
        }
    },
    data: function() {
        return {
            currVal: 0,
            frames: [],
            nextKey: 0
        }
    },
    mounted() {
        for(let i = 0; i < this.anim.frames.length; ++i) {
            this.frames.push( {
                frame: this.anim.frames[i],
                key: this.nextKey
            })
            this.nextKey = this.nextKey + 1;
        }
    },
	computed: {
       ...mapGetters([
            'atlasFrames'
       ]),
	}
}
