


































































































































































































































import * as PIXI from "pixi.js";
import { mapGetters } from "vuex";
import { Chrome } from "vue-color";
import PropEditorVec2 from "./property-editors/proped-vec2.vue";
import PropEditorFloat from "./property-editors/proped-float.vue";
import PropEditorColor from "./property-editors/proped-color.vue";
import PropEditorAffectorScale from "./property-editors/proped-aff-scale.vue";
import PropEditorAffectorRotate from "./property-editors/proped-aff-rotate.vue";
import PropEditorAffectorFade from "./property-editors/proped-aff-fade.vue";
import PropEditorAffectorColorBlend from "./property-editors/proped-aff-colorblend.vue";
import PropEditorAffectorFlipbook from "./property-editors/proped-aff-flipbook.vue";
import PropEditorAffectorForce from "./property-editors/proped-aff-force.vue";
import PropEditorEmissionShape from "./property-editors/proped-emission-shape.vue";
import PropEditorFrames from "./property-editors/proped-frames.vue";
import PropEditorAnims from "./property-editors/proped-anims.vue";
import PropEditorEmissionMode from "./property-editors/proped-emission-mode.vue";
import { Vec2PropConfig, ColorPropertyMode } from "../pfx/emitterConfig";
import { safeParseFloat } from "../utils";

export default {
  name: "EditorPane",
  components: {
    Chrome,
    PropEditorVec2,
    PropEditorFloat,
    PropEditorColor,
    PropEditorAffectorScale,
    PropEditorAffectorRotate,
    PropEditorAffectorFade,
    PropEditorAffectorColorBlend,
    PropEditorAffectorFlipbook,
    PropEditorAffectorForce,
    PropEditorEmissionShape,
    PropEditorFrames,
    PropEditorAnims,
    PropEditorEmissionMode,
  },
  props: ["emitterIdx"],
  methods: {
    triggerChangeWithReset: function () {
      this.$store.commit("resetNeeded");
      this.$store.commit("emitterChanged");
    },
    triggerChangeNoReset: function () {
      this.$store.commit("emitterChanged");
    },
  },
  mounted() {
    setInterval(() => {
      if (this.$refs.propertiesCollapse) {
        this.$refs.propertiesCollapse.$children.forEach((item) => {
          item.changeHeight();
        });
      }

      if (this.$refs.affectorsCollapse) {
        this.$refs.affectorsCollapse.$children.forEach((item) => {
          item.changeHeight();
        });
      }

      if (this.$refs.emissionSettingsCollapse) {
        this.$refs.emissionSettingsCollapse.$children.forEach((item) => {
          item.changeHeight();
        });
      }
    }, 50);
  },
  computed: {
    ...mapGetters(["atlasFrames", "atlases", "effectCfg"]),
    blendMode: {
      get() {
        switch (this.emitter.blendMode) {
          case PIXI.BLEND_MODES.NORMAL:
            return "normal";
          case PIXI.BLEND_MODES.ADD:
            return "additive";
          case PIXI.BLEND_MODES.NONE:
            return "opaque";
        }
      },
      set(newValueFromInput: string) {
        let blendMode = PIXI.BLEND_MODES.NORMAL;
        if (newValueFromInput == "additive") {
          blendMode = PIXI.BLEND_MODES.ADD;
        } else if (newValueFromInput == "opaque") {
          blendMode = PIXI.BLEND_MODES.NONE;
        }
        this.emitter.blendMode = blendMode;
        this.triggerChangeWithReset();
      },
    },
    maxParticles: {
      get() {
        return this.emitter.maxParticles;
      },

      set(newValueFromInput: string) {
        this.emitter.maxParticles = safeParseFloat(newValueFromInput);
        this.triggerChangeWithReset();
      },
    },
    worldSpace: {
      get() {
        return this.emitter.worldSpace;
      },

      set(newValueFromInput: boolean) {
        this.emitter.worldSpace = newValueFromInput;
        this.triggerChangeWithReset();
      },
    },
    followParent: {
      get() {
        return this.emitter.followParent;
      },

      set(newValueFromInput: boolean) {
        this.emitter.followParent = newValueFromInput;
        this.triggerChangeWithReset();
      },
    },
    zFollowsY: {
      get() {
        return this.emitter.zFollowsY;
      },

      set(newValueFromInput: boolean) {
        this.emitter.zFollowsY = newValueFromInput;
        this.triggerChangeNoReset();
      },
    },
    spawnRate: {
      get() {
        return this.emitter.spawnRate;
      },
      set(newValueFromInput: string) {
        this.emitter.spawnRate = safeParseFloat(newValueFromInput);
        this.triggerChangeNoReset();
      },
    },
    name: {
      get() {
        return this.emitter.name;
      },
      set(newValueFromInput: string) {
        this.emitter.name = newValueFromInput;
        // this.triggerChangeWithReset()
        // this.$store.commit("nameChanged", newValueFromInput)
      },
    },
    atlas: {
      get() {
        let ret = this.emitter.atlas.replace(/\.[^/.]+$/, "");
        return ret;
      },
      set(newValueFromInput: string) {
        this.emitter.atlas = newValueFromInput + ".atlas";
        this.emitter.atlasTexture = newValueFromInput + ".png";
        this.triggerChangeWithReset();
      },
    },
    emitter: {
      get() {
        return this.effectCfg.emitters[this.emitterIdx];
      },
    },
    ofsX: {
      get() {
        return this.emitter.ofsX;
      },
      set(newValueFromInput: string) {
        console.log("NEW VALUE IS NOW " + newValueFromInput);
        this.emitter.ofsX = safeParseFloat(newValueFromInput);
        this.triggerChangeWithReset();
      },
    },
    ofsY: {
      get() {
        return this.emitter.ofsY;
      },
      set(newValueFromInput: string) {
        this.emitter.ofsY = safeParseFloat(newValueFromInput);
        this.triggerChangeWithReset();
      },
    },
    startDelay: {
      get() {
        return this.emitter.startDelay.delayTime;
      },
    },
  },
};
