














import { mapGetters } from 'vuex'
import PropEditorAnim from "./proped-anim.vue";

export default {
	name: 'PropEditorAnims',
    components: {
        PropEditorAnim,
    },
    props: {
        onchange: Function, 
        emitter: Object,
        inner: {
            type: Boolean,
            default: false
        },
    },
    enums: {
    },
    watch: { 
        emitter: function(newVal, oldVal) { 
            if(newVal != oldVal) {
                this.initVals();
            }
        }
    },
    methods: {
        triggerChange: function() {
            let collapsed = [];
            for(let i = 0; i < this.anims.length; ++i) {
                collapsed.push(this.anims[i].anim)
            }
            this.emitter.anims = collapsed;
            this.onchange()
        },
        addAnimClicked: function(event) {
            this.anims.push({
                anim: {
                    frames: []
                },
                key: this.nextKey
            })
            this.nextKey = this.nextKey + 1;
            this.triggerChange();
        },
        removeAnimClicked: function(event, idx) {
            this.anims.splice(idx,1); 
            this.triggerChange();
        },
        initVals: function() {
            this.anims = [];
            for(let i = 0; i < this.emitter.anims.length; ++i) {
                this.anims.push( {
                    anim: this.emitter.anims[i],
                    key: this.nextKey
                })
                this.nextKey = this.nextKey + 1;
            }
        }
    },
    data: function() {
        return {
            anims: [],
            nextKey: 0
        }
    },
    mounted() {
        this.initVals();
    },
	computed: {
       ...mapGetters([
            'atlasFrames'
       ]),
	}
}
