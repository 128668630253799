import { Renderer as PIXIRenderer } from 'pixi.js'
import { InstancedSpriteBatcher } from './pfx/instanced-sprite-batcher'
import { IParticleRendererCamera } from './pfx/sprite-particle-renderer'

export enum RenderQueueElementType {
	InstancedSprite,
	InstancedSpriteGroup,
	DisplayObject,
}

interface RenderQueueElement {
	elType: RenderQueueElementType
	zIndex: number
	element: any
}

export class RenderQueue {
	private elements: RenderQueueElement[] = []
	private pixiRenderer: PIXIRenderer
	private instancedSpriteBatcher: InstancedSpriteBatcher

	constructor(pixiRenderer: PIXIRenderer, instancedSpriteBatcher: InstancedSpriteBatcher) {
		this.pixiRenderer = pixiRenderer
		this.instancedSpriteBatcher = instancedSpriteBatcher
	}

	clear() {
		this.elements = []
	}

	addElement(element: any, elType: RenderQueueElementType, zIndex: number) {
		this.elements.push({ elType, zIndex, element })
	}

	flush(camera: IParticleRendererCamera) {
		this._sort()
		this._render(camera)
	}

	private _sort() {
		this.elements.sort((a, b) => a.zIndex - b.zIndex)
	}

	private _switchToPixiRendering() {
		this.instancedSpriteBatcher.flush()
		this.pixiRenderer.batch.currentRenderer.start()
	}

	private _switchToInstancedRendering(camera: IParticleRendererCamera) {
		this.pixiRenderer.batch.currentRenderer.flush()
		this.pixiRenderer.batch.flush()
		this.instancedSpriteBatcher.beginRender(camera)
	}

	private _render(camera: IParticleRendererCamera) {
		let renderingInstances = false
		this.instancedSpriteBatcher.reset()

		this.elements.forEach((el) => {
			switch (el.elType) {
				case RenderQueueElementType.InstancedSprite:
					{
						const element = el.element
						if (!renderingInstances) {
							this._switchToInstancedRendering(camera)
							renderingInstances = true
						}
						this.instancedSpriteBatcher.addInstancedSprite(element)
					}
					break

				case RenderQueueElementType.InstancedSpriteGroup:
					{
						const element = el.element
						if (!renderingInstances) {
							this._switchToInstancedRendering(camera)
							renderingInstances = true
						}
						this.instancedSpriteBatcher.addInstancedSprites(element.particles, element.numParticles)
					}
					break

				case RenderQueueElementType.DisplayObject:
					{
						if (renderingInstances) {
							this._switchToPixiRendering()
							renderingInstances = false
						}
						const element = el.element as PIXI.DisplayObject

						element.updateTransform()
						element.render(this.pixiRenderer)
					}
					break
			}
		})
		if (renderingInstances) {
			this.instancedSpriteBatcher.flush()
		} else {
			this.pixiRenderer.batch.currentRenderer.flush()
		}
	}
}
