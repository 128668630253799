































import { mapGetters } from 'vuex'
import { getEffect } from "../pfx-view"
import { Compact } from 'vue-color'
import { pfx2vue } from '../utils'

export default {
	name: 'DebugInfoPanel',
	components: {
		Compact,
	},
	props: {
		emitterIdx: Number,
        showNumEmitters: { type: Boolean, default: true },
        showNumDrawCalls: { type: Boolean, default: true },
        showNumAffectors: { type: Boolean, default: true },
        showActiveParticles: { type: Boolean, default: true },
        showEmitTime: { type: Boolean, default: true },
        showUpdateTime: { type: Boolean, default: true },
	},
	data () {
		return {
			polling: null,
			numEmitters: 0,
			numDrawCalls: 0,
			numAffectors: 0,
			activeParticles: 0,
			emitTime: 0,
			updateTime: 0,
		}
	},
	methods: {
		pollData () {
			this.polling = setInterval(() => {
				const effect = getEffect()
				if(effect) {
					this.numEmitters = effect.emitters.length;
					this.numDrawCalls = effect.getNumDrawCalls();
					this.numAffectors = effect.getNumAffectors();
					this.activeParticles = effect.getNumActiveParticles();
					this.emitTime = effect.getAverageTimeEmittingSinceLastCheck();
					this.updateTime = effect.getAverageTimeUpdatingSinceLastCheck();
				}
			}, 100)
		},
        triggerChange: function() {
        }
	},
	beforeDestroy () {
		clearInterval(this.polling)
	},
	created () {
		this.pollData()
	},
	computed: {
	},
}
