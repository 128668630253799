import * as PIXI from "pixi.js"
import { EmitterConfig, Vec2PropertyMode, FloatPropertyMode, ColorPropertyMode, FadeAffectorMode, ScaleAffectorMode, ColorBlendAffectorMode, RotateAffectorMode, EmissionShape, EmissionMode} from "./pfx/emitterConfig"
import { EffectConfig, CURRENT_EFFECT_VERSION } from "./pfx/effectConfig"

export const defaultEmitter: EmitterConfig = {
    // atlas: "pfx-alpha/pfx-alpha.atlas",
    // atlasTexture: "pfx-alpha/pfx-alpha.png",
    ofsX: 0,
    ofsY: 0,
    name: "default",
    atlas: "pfx/pfx-multi/pfx-multi.atlas",
    atlasTexture: "pfx/pfx-multi/pfx-multi.png",
    shape: EmissionShape.Point,
    blendMode: PIXI.BLEND_MODES.ADD,
    maxParticles: 16,
    worldSpace: true,
    followParent: false,

    velocity: { mode: Vec2PropertyMode.Random, min: { x: -150, y: -150 }, max: { x: 150, y: 150 }, },
    rot: { mode: FloatPropertyMode.Disabled },

    color: {
        mode: ColorPropertyMode.Constant,
        min: {
            r: 0.8,
            g: 0.3,
            b: 0.3,
            a: 1.0
        },
        max: {
            r: 0.8,
            g: 0.8,
            b: 0.3,
            a: 1.0
        },
        val: {
            r: 1,
            g: 1,
            b: 1,
            a: 1
        },
    },
    lifetime: { mode: FloatPropertyMode.Constant, val: 1.0 },
    scale: { mode: FloatPropertyMode.Random, min: 1.0, max: 2.0 },
    frames: ["pfx1", "pfx2", "pfx3"],
    anims: [
        {
            frames: [
                "pfx1", "pfx2"
            ]
        }
    ],
    affectors: [
    ],
    emissionMode: EmissionMode.Continuous,
    continuous: {
        spawnRate: {
            mode: FloatPropertyMode.Constant,
            val: 1,
        }
    },
    startDelay:{
        delayTime: {
            mode: FloatPropertyMode.Constant,
            val: 1
        }
    }
}

export const defaultEffect: EffectConfig = {
    version: CURRENT_EFFECT_VERSION,
    name: "default",
    emitters: [
        defaultEmitter,
    ]
}