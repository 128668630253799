

































import { mapGetters } from 'vuex'
import { FloatPropertyMode } from '../../pfx/emitterConfig'
import { safeParseFloat, deg2rad, rad2deg, formatNumber } from '../../utils'

export default {
	name: 'PropEditorFloat',
    components: {},
    props: {
        min: Number,
        max: Number,
        onchange: Function, 
        prop: Object,
        canDisable: Boolean,
        inner: {
            type: Boolean,
            default: false
        },
        step: {
            type: Number,
            default: 1
        }
    },
    enums: {
       FloatPropertyMode 
    },
    methods: {
        triggerChange: function() {
            this.onchange()
        },
    },
    data: function() {
        return {
            disabled: this.prop.mode == FloatPropertyMode.Disabled
        }
    },
    mounted() {
    },
	computed: {
       mode: {
			get() { 
                return this.prop.mode 
            },
			set(newVal:number) { 
                this.prop.mode = newVal; 
                this.disabled = newVal == FloatPropertyMode.Disabled;
                this.triggerChange(); 
            }
        },
        randMin: {
            get() { 
                return formatNumber(this.prop.min)
            },
            set(newVal:string) 
            { 
                this.prop.min = safeParseFloat(newVal);
                this.triggerChange(); 
            }
        },
        randMax: {
            get() { 
                return formatNumber(this.prop.max)
            },
            set(newVal:string) { 
                this.prop.max = safeParseFloat(newVal);
                this.triggerChange(); 
            }
        },
        val: {
            get() { 
                return formatNumber(this.prop.val)
            },
            set(newVal:string) { 
                this.prop.val = safeParseFloat(newVal);
                this.triggerChange(); 
            }
        },
	}
}
