






























import { mapGetters } from 'vuex'
import { RotateAffectorMode, RotateAffectorConfig, FloatPropertyMode } from '../../pfx/emitterConfig'
import PropEditorFloat from "./proped-float.vue";
import { safeParseFloat, formatNumber, findAffector, removeAffector } from '../../utils'

export default {
	name: 'PropEditorAffectorRotate',
    components: {
		PropEditorFloat,
    },
    props: {
        onchange: Function, 
        emitter: Object
    },
    enums: {
       RotateAffectorMode 
    },
    data: function () {
        return {
        }
    },
    methods: {
        triggerChange: function() {
            this.onchange();
        },
        createAffector: function(initialMode) {
            return {
                mode: initialMode,
                constSpeed: {
                    mode: FloatPropertyMode.Constant,
                    val: 0,
                    min: 0,
                    max: 0
                },
                target: {
                    mode: FloatPropertyMode.Constant,
                    val: 0,
                    min: 0,
                    max: 0
                },
                times: [
                    {
                        mode: FloatPropertyMode.Constant,
                        val: 0.5,
                        min: 0,
                        max: 0
                    },
                    {
                        mode: FloatPropertyMode.Constant,
                        val: 0.5,
                        min: 0,
                        max: 0
                    }
                ]
            }
        }
    },
	computed: {
        mode: {
            get() {
                let aff:RotateAffectorConfig = findAffector(this.emitter, "rotate");
                if(!aff)
                    return RotateAffectorMode.Disabled;
                return aff.mode;
            },
            set(newVal:number) {
                let aff:RotateAffectorConfig = findAffector(this.emitter, "rotate");
                if(!aff) {
                    aff = this.createAffector(newVal);
                    this.emitter.affectors.push({
                        id: "rotate",
                        cfg: aff
                    });
                } else {
                    if(newVal == RotateAffectorMode.Disabled) {
                        removeAffector(this.emitter, "rotate")
                    } else {
                        aff.mode = newVal;
                    }
                }

                this.triggerChange();
            }
        },
        constSpeed: {
            get() {
                let aff:RotateAffectorConfig = findAffector(this.emitter, "rotate");
                return aff.constSpeed;
            },
        },
        target: {
            get() {
                let aff:RotateAffectorConfig = findAffector(this.emitter, "rotate");
                return aff.target;
            }
        },
        times0: {
            get() {
                let aff:RotateAffectorConfig = findAffector(this.emitter, "rotate");
                return aff.times[0];
            }
        },
        times1: {
            get() {
                let aff:RotateAffectorConfig = findAffector(this.emitter, "rotate");
                return aff.times[1];
            }
        }
	}
}
