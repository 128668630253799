






























import { mapGetters } from 'vuex'
import { EmissionShape, BoxEmitterPropConfig, CircleEmitterPropConfig } from '../../pfx/emitterConfig'
import PropEditorFloat from "./proped-float.vue";
import { findAffector, removeAffector } from '../../utils'

export default {
	name: 'PropEditorEmissionShape',
    components: {
		PropEditorFloat,
    },
    props: {
        onchange: Function, 
        emitter: Object
    },
    enums: {
        EmissionShape
    },
    data: function () {
        return {
        }
    },
    methods: {
        triggerChange: function() {
            this.onchange();
        },
    },
	computed: {
        mode: {
            get() {
                return this.emitter.shape;
            },
            set(newVal) {
                this.emitter.shape = newVal;
                this.triggerChange();
            }
        },
        boxWidth: {
            get() {
                return this.emitter.boxEmitter.width;
            },
        },
        boxHeight: {
            get() {
                return this.emitter.boxEmitter.height;
            },
        },
        circleRadius: {
            get() {
                return this.emitter.circleEmitter.radius;
            },
        },
        circleEdgeOnly: {
            get() {
                return this.emitter.circleEmitter.edgeOnly;
            },
            set(newVal) {
                this.emitter.circleEmitter.edgeOnly = newVal;
                this.triggerChange();
            },
        }
	}
}
