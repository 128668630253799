import { Texture } from 'pixi.js'
import { Particle } from './emitter'

export enum FloatPropertyMode {
	Random,
	Constant,
	Disabled,
}

export enum Vec2PropertyMode {
	Random,
	Constant,
	Disabled,
}

export enum ColorPropertyMode {
	Random,
	Constant,
	Disabled,
}

export interface AnimFrame {
	uvShift: number[]
	uvSize: number[]
	ofs: number[]
	size: number[]
}

export interface AnimPropConfig {
	frames: string[]
}

export interface Vec2PropConfig {
	mode: Vec2PropertyMode
	min?: { x: number; y: number }
	max?: { x: number; y: number }
	val?: { x: number; y: number }
}

export interface ColorPropConfig {
	mode: ColorPropertyMode
	min?: { r: number; g: number; b: number; a?: number }
	max?: { r: number; g: number; b: number; a?: number }
	val?: { r: number; g: number; b: number; a?: number }
}

export interface FloatPropConfig {
	mode: FloatPropertyMode
	min?: number
	max?: number
	val?: number
}

export function getFloatFromPropConfig(cfg: FloatPropConfig, defaultVal: number = 0.0) {
	switch (cfg.mode) {
		case FloatPropertyMode.Constant:
			return cfg.val
		case FloatPropertyMode.Random:
			return cfg.min + Math.random() * (cfg.max - cfg.min)
	}
	return defaultVal
}

export function getReciprocalFloatFromPropConfig(cfg: FloatPropConfig, defaultVal: number = 0.0) {
	switch (cfg.mode) {
		case FloatPropertyMode.Constant:
			return 1.0 / cfg.val
		case FloatPropertyMode.Random:
			return 1.0 / (cfg.min + Math.random() * (cfg.max - cfg.min))
	}
	return defaultVal
}

export function getVec2FromPropConfig(cfg: Vec2PropConfig, out: number[], defaultX: number = 0.0, defaultY: number = 0.0) {
	switch (cfg.mode) {
		case Vec2PropertyMode.Constant:
			out[0] = cfg.val.x
			out[1] = cfg.val.y
			break
		case Vec2PropertyMode.Random:
			out[0] = cfg.min.x + Math.random() * (cfg.max.x - cfg.min.x)
			out[1] = cfg.min.y + Math.random() * (cfg.max.y - cfg.min.y)
			break
		default:
			out[0] = defaultX
			out[1] = defaultY
	}
}

export function getColorFromPropConfig(
	cfg: ColorPropConfig,
	out: number[],
	defaultR: number = 1.0,
	defaultG: number = 1.0,
	defaultB: number = 1.0,
	defaultA: number = 1.0,
) {
	switch (cfg.mode) {
		case ColorPropertyMode.Constant:
			out[0] = cfg.val.r
			out[1] = cfg.val.g
			out[2] = cfg.val.b
			out[3] = cfg.val.a
			break
		case ColorPropertyMode.Random:
			out[0] = cfg.min.r + Math.random() * (cfg.max.r - cfg.min.r)
			out[1] = cfg.min.g + Math.random() * (cfg.max.g - cfg.min.g)
			out[2] = cfg.min.b + Math.random() * (cfg.max.b - cfg.min.b)
			out[3] = cfg.min.a + Math.random() * (cfg.max.a - cfg.min.a)
			break
		default:
			out[0] = defaultR
			out[1] = defaultG
			out[2] = defaultB
			out[3] = defaultA
	}
}

export interface BoxEmitterPropConfig {
	width: FloatPropConfig
	height: FloatPropConfig
}

export interface CircleEmitterPropConfig {
	radius: FloatPropConfig
	edgeOnly: boolean
}

export interface FadePropConfig {
	start: FloatPropConfig
	end?: FloatPropConfig
	times?: FloatPropConfig[] // [0] = normalized age when start fade should be reached,
	// [1] = normalized age when fading to end should start
}

export enum FadeAffectorMode {
	Constant,
	OverLife,
	InOutOverLife,
	Disabled,
}

export enum ScaleAffectorMode {
	Constant,
	OverLife,
	InOutOverLife,
	Disabled,
}

export enum RotateAffectorMode {
	Constant,
	OverLife,
	InOutOverLife,
	Disabled,
}

export enum ColorBlendAffectorMode {
	OverLife,
	InOutOverLife,
	Disabled,
}

export enum FlipbookAffectorMode {
	Constant,
	OverLife,
	OverLifeWithHold,
	Disabled,
}

export enum ForceAffectorMode {
	Constant,
	Disabled,
}

export interface AffectorConfig {
	id: string
	cfg: any
}

export interface ForceAffectorConfig {
	mode: ForceAffectorMode
	force: Vec2PropConfig
}

export interface FadeAffectorConfig {
	mode: FadeAffectorMode
	target?: FloatPropConfig
	constSpeed?: FloatPropConfig
	times?: FloatPropConfig[] // [0] = normalized age when start fade should be reached,
	// [1] = normalized age when fading to end should start
}

export interface ScaleAffectorConfig {
	mode: ScaleAffectorMode
	target?: FloatPropConfig
	constSpeed?: FloatPropConfig
	times?: FloatPropConfig[] // [0] = normalized age when start scale should be reached,
	// [1] = normalized age when scaling to end should start
}

export interface RotateAffectorConfig {
	mode: RotateAffectorMode
	target?: FloatPropConfig
	constSpeed?: FloatPropConfig
	times?: FloatPropConfig[] // [0] = normalized age when start rot should be reached,
	// [1] = normalized age when rotating to end should start
}

export interface ColorBlendAffectorConfig {
	mode: ColorBlendAffectorMode
	mid?: ColorPropConfig
	target?: ColorPropConfig
	times?: FloatPropConfig[] // [0] = normalized age when start->mid should start
	// [1] = normalized age when mid->end should start
}

export interface FlipbookAffectorConfig {
	mode: FlipbookAffectorMode
	holdFrame?: FloatPropConfig
	constSpeed?: FloatPropConfig
	loop?: boolean
	times?: FloatPropConfig[] // [0] = normalized age when to reach hold frame
	// [1] = normalized age when to leave hold frame and complete anim
}

export enum EmissionShape {
	Point,
	Box,
	Circle,
}

export enum EmissionMode {
	Continuous,
	Burst,
}

export interface BurstEmissionConfig {
	numParticles: FloatPropConfig
	repeat: boolean
	timeBetweenBursts: FloatPropConfig
}

export interface ContinuousEmissionConfig {
	spawnRate: FloatPropConfig
}

export interface StartDelayConfig {
	delayTime: FloatPropConfig
}

export interface EmitterConfig {
	name: string
	ofsX: number
	ofsY: number
	atlas: string
	atlasTexture: string
	maxParticles: number
	shape: EmissionShape
	emissionMode: EmissionMode
	worldSpace: boolean
	followParent: boolean
	color: ColorPropConfig
	lifetime: FloatPropConfig
	scale: FloatPropConfig
	rot: FloatPropConfig
	frames: string[]
	zFollowsY?: boolean
	anims?: AnimPropConfig[]
	boxEmitter?: BoxEmitterPropConfig
	circleEmitter?: CircleEmitterPropConfig
	velocity: Vec2PropConfig
	blendMode: PIXI.BLEND_MODES
	affectors: AffectorConfig[]
	startDelay: StartDelayConfig

	burst?: BurstEmissionConfig
	continuous?: ContinuousEmissionConfig
}

export interface ParticleRendererConfig {
	texture: Texture
	maxParticles: number
	blendMode: PIXI.BLEND_MODES
}
