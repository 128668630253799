






















import { mapGetters } from 'vuex'
import { Compact } from 'vue-color'
import { pfx2vue } from '../utils'

export default {
	name: 'SettingsMenu',
	components: {
		Compact,
	},
	props: [],
	data () {
		return {
			currBgColor: { r: 0, g: 0, b: 0, a: 1.0 }
		}
	},
	methods: {
	},
	beforeDestroy () {
	},
	created () {
	},
	computed: {
        bgColor: {
            get() { 
                // Convert from pfx format to vue-color
                return pfx2vue(this.currBgColor);
            },
            set(newVal:any) 
            { 
                // Convert from vue-color to pfx format
                this.currBgColor.r = newVal.rgba.r / 255.0;
                this.currBgColor.g = newVal.rgba.g / 255.0;
                this.currBgColor.b = newVal.rgba.b / 255.0;
                this.$store.commit("newBGColor", this.currBgColor);
            }
        },
        followMouse: {
            get() {
                return this.$store.state.followMouse;
            },
            set(newVal:boolean) {
                this.$store.commit("followMouseChanged", newVal);
            }
        },
        isolateEmitter: {
            get() {
                return this.$store.state.isolateEmitter;
            },
            set(newVal:boolean) {
                this.$store.commit("isolateEmitterChanged", newVal);
            }
        }
	},
}
