




























import { mapGetters } from 'vuex'
import { EmissionMode, BurstEmissionConfig, ContinuousEmissionConfig } from '../../pfx/emitterConfig'
import PropEditorFloat from "./proped-float.vue";

export default {
	name: 'PropEditorEmissionMode',
    components: {
		PropEditorFloat,
    },
    props: {
        onchange: Function, 
        emitter: Object
    },
    enums: {
        EmissionMode
    },
    data: function () {
        return {
        }
    },
    methods: {
        triggerChange: function() {
            this.onchange();
        },
    },
	computed: {
        mode: {
            get() {
                return this.emitter.emissionMode;
            },
            set(newVal) {
                this.emitter.emissionMode = newVal;
                this.triggerChange();
            }
        },
        spawnRate: {
            get() {
                return this.emitter.continuous.spawnRate;
            },
        },
        burstRepeat: {
            get() {
                return this.emitter.burst.repeat;
            },
            set(newVal) {
                this.emitter.burst.repeat = newVal;
                this.triggerChange();
            },
        },
        numParticles: {
            get() {
                return this.emitter.burst.numParticles;
            },
        },
        timeBetweenBursts: {
            get() {
                return this.emitter.burst.timeBetweenBursts;
            },
        },
	}
}
